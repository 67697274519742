import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"scrollable":"","persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{attrs:{"color":"primary"}},[_vm._v(" User Access Permissions "),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","icon":"","color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" X ")])],1),_c(VDivider),_c(VCardText,{staticStyle:{"height":"400px"}},[_c(VContainer,[_c(VTreeview,{attrs:{"selectable":"","items":_vm.$store.getters['layout/menuItems'],"item-key":"name","item-val":"name","open-all":"","selection-type":"independent","return-object":"","item-disabled":"locked"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.permissions),callback:function ($$v) {_vm.permissions=$$v},expression:"permissions"}})],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mr-3",attrs:{"color":"blue darken-1","dark":"","loading":_vm.loading},on:{"click":_vm.saveUserPermissions}},[_vm._v(" Save Permission ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }